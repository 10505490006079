import { AspectRatio } from '@/shadcn-ui/components/ui/aspect-ratio';
import { ShadCnButton } from '@/shadcn-ui/components/ui/button';
import Link from 'next/link';

interface LandingPageDefaultSectionProps {
  textOrientation: 'left' | 'right';
  title: string;
  titleWithGradient: string;
  subtitle: string;
  cta: string;
  imageUrl: string;
  trialLink: string;
  fontFamily?: string;
  hasBackground?: boolean;
}
export function LandingPageDefaultSection({
  textOrientation,
  title,
  titleWithGradient,
  subtitle,
  cta,
  imageUrl,
  trialLink,
  fontFamily = 'font-poppins',
  hasBackground = true,
}: LandingPageDefaultSectionProps) {
  return (
    <div
      className={`flex flex-col items-center justify-center w-full ${
        hasBackground ? 'bg-[#F9FAFB]' : ''
      } ${fontFamily}`}
    >
      <div
        className={`w-full flex flex-col items-center max-w-7xl gap-8 py-12 px-4  ${
          textOrientation === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'
        }`}
      >
        <div className="w-full md:w-1/2 flex flex-col gap-4 md:gap-6 items-start justify-center">
          <div className="text-3xl md:text-4xl font-bold text-gray-700">
            {title}&nbsp;
            <span className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF]  text-transparent bg-clip-text">
              {titleWithGradient}
            </span>
          </div>
          <p className="text-gray-500 text-xl md:text-2xl">{subtitle}</p>
          <ShadCnButton
            variant="link"
            className="text-lg md:text-xl font-semibold px-0"
          >
            <p className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF] text-transparent bg-clip-text">
              <Link href={trialLink}>{cta}</Link>
            </p>
          </ShadCnButton>
        </div>
        <div className="w-full md:w-1/2 h-fit flex items-center justify-center ease-in-out hover:scale-[1.03] transition-transform duration-200">
          <AspectRatio ratio={3 / 2}>
            <img
              src={imageUrl}
              alt={title}
              className="rounded-md object-cover"
            />
          </AspectRatio>
        </div>
      </div>
    </div>
  );
}
